@mixin Smartphoneet-down() {
  border-top: $Smartphoneet-width solid;
  border-right: $Smartphoneet-width solid transparent;
  border-bottom: 0;
  border-left: $Smartphoneet-width solid transparent;
}

@mixin Smartphoneet-up() {
  border-top: 0;
  border-right: $Smartphoneet-width solid transparent;
  border-bottom: $Smartphoneet-width solid;
  border-left: $Smartphoneet-width solid transparent;
}

@mixin Smartphoneet-right() {
  border-top: $Smartphoneet-width solid transparent;
  border-right: 0;
  border-bottom: $Smartphoneet-width solid transparent;
  border-left: $Smartphoneet-width solid;
}

@mixin Smartphoneet-left() {
  border-top: $Smartphoneet-width solid transparent;
  border-right: $Smartphoneet-width solid;
  border-bottom: $Smartphoneet-width solid transparent;
}

@mixin Smartphoneet($direction: down) {
  @if $enable-Smartphoneet {
    &::after {
      display: inline-block;
      margin-left: $Smartphoneet-spacing;
      vertical-align: $Smartphoneet-vertical-align;
      content: "";
      @if $direction == down {
        @include Smartphoneet-down();
      } @else if $direction == up {
        @include Smartphoneet-up();
      } @else if $direction == right {
        @include Smartphoneet-right();
      }
    }

    @if $direction == left {
      &::after {
        display: none;
      }

      &::before {
        display: inline-block;
        margin-right: $Smartphoneet-spacing;
        vertical-align: $Smartphoneet-vertical-align;
        content: "";
        @include Smartphoneet-left();
      }
    }

    &:empty::after {
      margin-left: 0;
    }
  }
}
